<template>
  <v-row style="height: 100%" no-gutters>
    <v-col
      cols="12"
      sm="12"
      md="6"
      lg="4"
      class="pa-12 d-flex flex-column justify-space-between elevation-2"
      style="z-index: 1"
    >
      <div class="d-flex align-center">
        <v-avatar tile size="58">
          <v-img
            src="/favicon.ico"
            alt="home"
          />
        </v-avatar>

        <g-app-title />
      </div>

      <div>
        <v-form color="secondary">
          <h2 class="my-6">
            {{ $t("page_login.title") }}
          </h2>

          <client-only>
            <v-select
              v-if="internalProviders.length > 1"
              v-model="selected"
              :label="$t('general.authenticator')"
              outlined
              :items="internalProviders"
              :menu-props="{
                'transition': 'slide-y-transition',
                'offset-y': true
              }"
            />

            <g-form-base
              ref="form"
              :valid.sync="valid"
              :fields="fields"
              :record="record"
              @submit="!loading && valid && onLogin()"
              @showpasswd="showPass($event)"
            />
          </client-only>

          <v-alert
            v-model="error"
            type="error"
            contained
            class="my-2"
            icon="mdi-alert-outline"
          >
            {{ $t('page_login.incorrect_user_password') }}
          </v-alert>

          <v-btn
            :disabled="!valid"
            block
            x-large
            color="primary"
            :loading="loading"
            @click="!loading && onLogin()"
          >
            <!-- <v-icon start icon="mdi-user"></v-icon> -->
            {{ $t("page_login.login_btn") }}
          </v-btn>
        </v-form>

        <div v-if="externalProviders.length" class="my-5 d-flex justify-center align-center">
          <v-divider />
          <span class="mx-4 text--secondary subtitle-1">
            {{ $t('general.or') }}
          </span>
          <v-divider />
        </div>

        <template v-for="(p, i) in externalProviders">
          <v-btn
            :key="i"
            block
            x-large
            text
            class="bg-shadow"
            @click="onClickProvider(p.value)"
          >
            <v-icon left>
              {{ p.icon }}
            </v-icon>

            {{ $t('general.login_with') }} {{ p.text }}
          </v-btn>
        </template>
      </div>

      <v-card flat class="d-flex flex-column justify-center">
        <v-btn v-if="PLATFORM_ALLOW_PWRECOVERY" class="text-decoration-underline" text plain to="/recover">
          {{ $t("page_login.recover_btn") }}
        </v-btn>

        <v-btn v-if="PLATFORM_ALLOW_REGISTER" class="text-decoration-underline" text plain to="/register">
          {{ $t("page_login.register_btn") }}
        </v-btn>

        <v-card-subtitle>
          {{ $t("general.version") }} {{ PLATFORM_VERSION }}
        </v-card-subtitle>
      </v-card>
    </v-col>

    <v-col
      class="hidden-sm-and-down"
      cols="0"
      sm="0"
      md="6"
      lg="8"
      align-self="stretch"
    >
      <v-card color="primary" style="height: 100%" tile class="d-flex justify-right">
        <v-img
          class="v-no-responsive-sizer"
          src="/landing.jpg"
          alt="wp"
          :contain="false"
          position="top right"
        />
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { defineComponent, ref, computed } from '@vue/composition-api';
import { useContext } from '@nuxtjs/composition-api';

import { useUser } from '@/stores/auth/user';
import { useI18n } from '@/composables/useI18n';

export default defineComponent({
  layout: 'fullscreen',

  setup () {
    const { $core, $config } = useContext();

    const { PLATFORM_VERSION, PLATFORM_TITLE, PLATFORM_SUBTITLE, PLATFORM_ALLOW_REGISTER, PLATFORM_ALLOW_PWRECOVERY, PLATFORM_LOGIN_ID } = $config;

    const i18n = useI18n();
    const userStore = useUser();

    const form = ref();
    const valid = ref(false);
    const checked = ref();
    const show = ref(false);
    const record = ref({ email: '', password: '' });

    const loading = computed(() => userStore.loading);
    const error = computed(() => userStore.error);

    let providers = [];

    if (process.client) {
      providers = $core.getProviders();
    }

    const internalProviders = providers.filter(p => p.value === 'default' || p.value === 'ldap');
    const externalProviders = providers.filter(p => p.value !== 'default' && p.value !== 'ldap');
    const selected = ref(internalProviders[0]?.value);

    const fieldsRows = ref(
      PLATFORM_LOGIN_ID
        ? [
            {
              component: 'v-text-field',
              params: {
                label: i18n.t('general.user_id'),
                'prepend-inner-icon': 'mdi-account-key'
              },
              field: 'email',
              rules: 'required'
            },
            {
              component: 'v-text-field',
              params: {
                label: i18n.t('general.password'),
                type: 'password',
                'prepend-inner-icon': 'mdi-lock',
                'append-icon': 'mdi-eye-off'
              },
              field: 'password',
              rules: 'required'
            }
          ]
        : [
            {
              component: 'v-text-field',
              params: {
                label: i18n.t('general.email'),
                type: 'email',
                'prepend-inner-icon': 'mdi-at'
              },
              field: 'email',
              rules: 'required|email'
            },
            {
              component: 'v-text-field',
              params: {
                label: i18n.t('general.password'),
                type: 'password',
                'prepend-inner-icon': 'mdi-lock',
                'append-icon': 'mdi-eye-off'
              },
              field: 'password',
              rules: 'required'
            }
          ]
    );

    const fields = computed({
      // getter
      get () {
        return fieldsRows.value;
      },
      // Setter
      set (newValue) {
        for (const value of fields.value) {
          if (value.field === 'password') {
            value.params.type = newValue ? 'text' : 'password';
            value.params['append-icon'] = newValue ? 'mdi-eye' : 'mdi-eye-off';
          }
        }
      }
    });

    return {
      loading,
      error,
      form,
      selected,
      internalProviders,
      externalProviders,
      valid,
      fields,
      record,
      checked,
      show,
      PLATFORM_VERSION,
      PLATFORM_TITLE,
      PLATFORM_SUBTITLE,
      PLATFORM_ALLOW_REGISTER,
      PLATFORM_ALLOW_PWRECOVERY,
      showPass,

      userStore,

      onLogin,
      onClickProvider
    };

    function onLogin () {
      const values = form.value.submit();

      $core.login(selected.value, values);
    }

    function onClickProvider (value) {
      // console.log('TODO ', value);
    }

    function showPass (show) {
      // Update Fields
      this.fields = show;
    }
  }
});
</script>
<style scoped>
.v-no-responsive-sizer >>> .v-responsive__sizer{
  padding-bottom: 0 !important;
}
</style>
