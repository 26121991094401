var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticStyle: { height: "100%" }, attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        {
          staticClass:
            "pa-12 d-flex flex-column justify-space-between elevation-2",
          staticStyle: { "z-index": "1" },
          attrs: { cols: "12", sm: "12", md: "6", lg: "4" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "v-avatar",
                { attrs: { tile: "", size: "58" } },
                [_c("v-img", { attrs: { src: "/favicon.ico", alt: "home" } })],
                1
              ),
              _vm._v(" "),
              _c("g-app-title"),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "v-form",
                { attrs: { color: "secondary" } },
                [
                  _c("h2", { staticClass: "my-6" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("page_login.title")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "client-only",
                    [
                      _vm.internalProviders.length > 1
                        ? _c("v-select", {
                            attrs: {
                              label: _vm.$t("general.authenticator"),
                              outlined: "",
                              items: _vm.internalProviders,
                              "menu-props": {
                                transition: "slide-y-transition",
                                "offset-y": true,
                              },
                            },
                            model: {
                              value: _vm.selected,
                              callback: function ($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("g-form-base", {
                        ref: "form",
                        attrs: {
                          valid: _vm.valid,
                          fields: _vm.fields,
                          record: _vm.record,
                        },
                        on: {
                          "update:valid": function ($event) {
                            _vm.valid = $event
                          },
                          submit: function ($event) {
                            !_vm.loading && _vm.valid && _vm.onLogin()
                          },
                          showpasswd: function ($event) {
                            return _vm.showPass($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-alert",
                    {
                      staticClass: "my-2",
                      attrs: {
                        type: "error",
                        contained: "",
                        icon: "mdi-alert-outline",
                      },
                      model: {
                        value: _vm.error,
                        callback: function ($$v) {
                          _vm.error = $$v
                        },
                        expression: "error",
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("page_login.incorrect_user_password")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.valid,
                        block: "",
                        "x-large": "",
                        color: "primary",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          !_vm.loading && _vm.onLogin()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("page_login.login_btn")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.externalProviders.length
                ? _c(
                    "div",
                    { staticClass: "my-5 d-flex justify-center align-center" },
                    [
                      _c("v-divider"),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "mx-4 text--secondary subtitle-1" },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.or")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("v-divider"),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.externalProviders, function (p, i) {
                return [
                  _c(
                    "v-btn",
                    {
                      key: i,
                      staticClass: "bg-shadow",
                      attrs: { block: "", "x-large": "", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.onClickProvider(p.value)
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { left: "" } }, [
                        _vm._v(
                          "\n            " + _vm._s(p.icon) + "\n          "
                        ),
                      ]),
                      _vm._v(
                        "\n\n          " +
                          _vm._s(_vm.$t("general.login_with")) +
                          " " +
                          _vm._s(p.text) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column justify-center",
              attrs: { flat: "" },
            },
            [
              _vm.PLATFORM_ALLOW_PWRECOVERY
                ? _c(
                    "v-btn",
                    {
                      staticClass: "text-decoration-underline",
                      attrs: { text: "", plain: "", to: "/recover" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("page_login.recover_btn")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.PLATFORM_ALLOW_REGISTER
                ? _c(
                    "v-btn",
                    {
                      staticClass: "text-decoration-underline",
                      attrs: { text: "", plain: "", to: "/register" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("page_login.register_btn")) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("v-card-subtitle", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("general.version")) +
                    " " +
                    _vm._s(_vm.PLATFORM_VERSION) +
                    "\n      "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        {
          staticClass: "hidden-sm-and-down",
          attrs: {
            cols: "0",
            sm: "0",
            md: "6",
            lg: "8",
            "align-self": "stretch",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex justify-right",
              staticStyle: { height: "100%" },
              attrs: { color: "primary", tile: "" },
            },
            [
              _c("v-img", {
                staticClass: "v-no-responsive-sizer",
                attrs: {
                  src: "/landing.jpg",
                  alt: "wp",
                  contain: false,
                  position: "top right",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }